// Loader.js

import React from 'react';
import loaderImage from '../images/loder.png'; // Import your loader image

const Loader = () => {
  return (
    <div className="preloader ">
      <div className="loader ">
        <div className="spinner ">
          <div className="animation-preloader">
            <div className="ctn-loader-img">
              <img src={loaderImage} alt="Loading..." className="loader-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
