import {
  faFacebook,
  faFacebookF,
  faGoogle,
  faInstagram,
  faLinkedin,
  faLinkedinIn,
  faXTwitter,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import logo from "../images/Logo_06-removebg-preview.png";


const Footer = () => {
  return (
    <div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-3">
              <div className="footer-head b_fotter">
                <img src={logo} alt="" />
                <h2>What We are?</h2>
              </div>
              <div className="footer-text">
                <p>
                  "Kalathiya Infotech leverages a diverse skill set to deliver
                  tailored solutions, driving success in every endeavor. With a
                  focus on innovation and customer satisfaction."
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="footer-head">
                <h2>Quick Link</h2>
              </div>
              <div className="footer-list">
                <ul>
                  <li>Hire Dedicated Development</li>
                  <li>Mobile Application Development</li>
                  <li>Web Development</li>
                  {/* <li>Career</li> */}
                </ul>
              </div>
            </div>
            <div className="col-6">
              <div className="footer-head">
                <h2>Contact Info</h2>
              </div>
              <div className="row">
                <div className="col-6 abc">
                  <div className="footer-list">
                    <h5>Surat</h5>
                    <div className="icon-data">
                      <FontAwesomeIcon className="i" icon={faMapMarkerAlt} />
                      <a
                        href="https://www.google.com/maps/place/Kalathiya+Infotech/@21.2057926,72.8818075,20.82z/data=!4m9!1m2!2m1!1s102-103+Silver+Point,+Jivanjyot+Circle,+Nr+Yogi+chowk+Road+Surat+-395010!3m5!1s0x26cb5e4230fc8877:0xd36ccfe485cd6a01!8m2!3d21.205932!4d72.8818071!16s%2Fg%2F11kbyh9bk6?entry=ttu"
                        target="_blank"
                      >
                       139, Silver Point, Jivanjyot Circle, Nr Yogi chowk
                        Road Surat -395010 (Head Office)
                      </a>
                    </div>
                    <div className="icon-data">
                      <FontAwesomeIcon className="i" icon={faPhone} />
                      <a href="tel:9327569959">+91 9327569959</a>
                    </div>
                    <div className="social-icon">
                      <a
                        href="https://www.linkedin.com/company/kalathiyainfotech/"
                        target="_blank"
                      >
                        {" "}
                        <FontAwesomeIcon className="i" icon={faLinkedinIn} />
                      </a>
                      <a
                        href="https://www.instagram.com/kalathiya_infotech/"
                        target="_blank"
                      >
                        <FontAwesomeIcon className="i" icon={faInstagram} />
                      </a>
                      <a
                        href="https://www.facebook.com/kalathiyainfotech"
                        target="_blank"
                      >
                        <FontAwesomeIcon className="i" icon={faFacebookF} />
                      </a>
                      <a
                        href="https://www.youtube.com/@KalathiyaInfotech"
                        target="_blank"
                      >
                        <FontAwesomeIcon className="i" icon={faYoutube} />
                      </a>
                      <a
                        href="https://www.google.com/search?q=kalathiya+infotech&rlz=1C1GCEA_enIN1018IN1018&oq=Kalathiya+&aqs=chrome.0.69i59l2j69i57j69i59j69i60l4.17467j0j7&sourceid=chrome&ie=UTF-8"
                        target="_blank"
                      >
                        <FontAwesomeIcon className="i" icon={faGoogle} />
                      </a>
                      <a href="https://x.com/KalathiyaInfo15" target="_blank">
                        <FontAwesomeIcon className="i" icon={faXTwitter} />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="footer-list">
                    <h5>Canada</h5>
                    <div className="icon-data">
                      <FontAwesomeIcon className="i" icon={faMapMarkerAlt} />
                      <a
                        href="https://www.google.com/maps/place/855+King+St+E+%23210,+Cambridge,+ON+N3H+3P2,+Canada/@43.3949383,-80.3584763,17z/data=!3m1!4b1!4m5!3m4!1s0x882b89f0377066e3:0x13fe980777df8121!8m2!3d43.3949383!4d-80.3559014?entry=ttu"
                        target="_blank"
                      >
                        855 King Street East,Unit 210 Cambridge, ON, Canada
                        (Branch)
                      </a>
                    </div>
                    <div className="icon-data">
                      <FontAwesomeIcon className="i" icon={faPhone} />
                      <a href="tel:+15199817124">+1 (519)9817124</a>
                    </div>
                    <div className="icon-data">
                      <FontAwesomeIcon className="i" icon={faEnvelope} />
                      <a href="mailto:info@kalathiyainfotech.com">
                        Info@kalathiyainfotech.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-list" />
            </div>
          </div>
        </div>
      </footer>

      <section className="footer-last">
        <div className="footer-last-data">
          <p>All &copy; by Kalathiya Infotech. All Rights Reserved.</p>
        </div>
      </section>
    </div>
  );
};

export default Footer;
