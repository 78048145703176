import React, { useRef, useState } from "react";
import "../css/media.css";
import { faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import emailjs from "@emailjs/browser";
const Touch = () => {
  const [ name, setName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ subject, setSubject ] = useState("");
  const [ message, setMessage ] = useState("");
  const [ nameError, setNameError ] = useState("");
  const [ emailError, setEmailError ] = useState("");
  const [ subjectError, setSubjectError ] = useState("");
  const [ messageError, setMessageError ] = useState("");

  const form = useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;

    // Check name
    if (!name.trim()) {
      setNameError("Please enter your name");
      isValid = false;
    } else {
      setNameError("");
    }

    // Check email
    if (!email.trim()) {
      setEmailError("Please enter your email");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Check subject
    if (!subject.trim()) {
      setSubjectError("Please enter a subject");
      isValid = false;
    } else {
      setSubjectError("");
    }

    // Check message
    if (!message.trim()) {
      setMessageError("Please enter your message");
      isValid = false;
    } else {
      setMessageError("");
    }

    if (isValid) {
      try {
        await fetch(
          "https://v1.nocodeapi.com/rinkuridota/google_sheets/bdmZdVGxPTkqHZud?tabId=Sheet1",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify([
              [ name, email, subject, message, new Date().toLocaleString() ]
            ])
          }
        );
        await emailjs
          .sendForm("service_ejkv38f", "template_963h23a", form.current, {
            publicKey: "KXP9_pJo_Zr8bRw96"
          })
          .then(
            () => {
              console.log("SUCCESS!");
            },
            (error) => {
              console.log("FAILED...", error.text);
            }
          );
        // console.log(response.data);
        // Clear form fields
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
        // Display success message
        toast.success("Thank you for sending message!");
      } catch (error) {
        console.error(error);
        // Display error message
        toast.error("Error sending message!" + error);
      }
    }
  };

  return (
    <div>
      <section className="contact-touch">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="contact-touch-data">
                <h3>We are here to help!</h3>
              </div>
              <div className="contact-icon">
                <div className="icon-data">
                  <h5>Surat</h5>
                  <div className="contact-address">
                    <FontAwesomeIcon className="i" icon={faMapMarkerAlt} />
                    <a
                      href="https://www.google.com/maps/place/Kalathiya+Infotech/@21.2057926,72.8818075,20.82z/data=!4m9!1m2!2m1!1s102-103+Silver+Point,
                      Jivanjyot+Circle,+Nr+Yogi+chowk+Road+Surat+-395010!3m5!1s0x26cb5e4230fc8877:0xd36ccfe485cd6a01!8m2!3d21.205932!4d72.8818071!16s%2Fg%2F11kbyh9bk6?entry=ttu"
                      target="_blank"
                    >
                      102-103 Silver Point, Jivanjyot Circle, Nr Yogi chowk Road
                      Surat -395010 (Head Office)
                    </a>
                  </div>
                  <div className="contact-address">
                    <FontAwesomeIcon className="i" icon={faPhone} />
                    <a href="tel:(0261)3102715">+0261 3102715</a>
                  </div>
                </div>
                <div className="icon-data">
                  <h5>Canada</h5>
                  <div className="contact-address">
                    <FontAwesomeIcon className="i" icon={faMapMarkerAlt} />
                    <a
                      href="https://www.google.com/maps/place/855+King+St+E+%23210,+Cambridge,+ON+N3H+3P2,+Canada/@43.3949383,-80.3584763,17z/data=!3m1!4b1!4m5!3m4!1s0x882b89f0377066e3:0x13fe980777df8121!8m2!3d43.3949383!4d-80.3559014?entry=ttu"
                      target="_blank"
                    >
                      855 King Street East,Unit 210 Cambridge, ON, Canada
                      (Branch)
                    </a>
                  </div>
                  <div className="contact-address">
                    <FontAwesomeIcon className="i" icon={faPhone} />
                    <a href="tel:+15199817124">+1 (519)9817124</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="border">
                <div>
                  <div className="contact-touch-head">
                    <h3>Let's Get In Touch</h3>
                  </div>
                  <div className="contact-touch-body">
                    <form ref={form} className="form" onSubmit={handleSubmit}>
                      <div className="mb-3 d-flex">
                        <input
                          type="text"
                          className={`form-control name ${nameError
                            ? "is-invalid"
                            : ""}`}
                          placeholder="Name"
                          value={name}
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        <input
                          type="email"
                          className={`form-control email ${emailError
                            ? "is-invalid"
                            : ""}`}
                          placeholder="E-mail"
                          value={email}
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="d-flex">
                        {nameError && (
                          <span className="invalid-feedback mt-0 mb-2 d-block">
                            {nameError}
                          </span>
                        )}
                        {emailError && (
                          <div className="invalid-feedback mt-0 mb-2 d-block">
                            {emailError}
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          className={`form-control email ${subjectError
                            ? "is-invalid"
                            : ""}`}
                          placeholder="Subject"
                          name="sunject"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          required
                        />
                        {subjectError && (
                          <div className="invalid-feedback">{subjectError}</div>
                        )}
                      </div>
                      <div className="mb-3">
                        <textarea
                          className={`form-control ${messageError
                            ? "is-invalid"
                            : ""}`}
                          placeholder="Your Message"
                          name="message"
                          rows={3}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          required
                        />
                        {messageError && (
                          <div className="invalid-feedback">{messageError}</div>
                        )}
                      </div>
                      <div className="button mb-3 d-flex">
                        <input
                          className="btn-contact"
                          type="submit"
                          value="SEND MESSAGE"
                        />
                        <Toaster />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Touch;
