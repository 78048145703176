import React from 'react'
import test from '../images/test.png'
import '../css/media.css'
import Footer from './Footer'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import quote1 from '../images/Double-quote1.png'
import quote2 from '../images/Double-quote2.png'
import quote3 from '../images/Double-quote3.png'
import quote4 from '../images/Double-quote4.png'
import vector1 from '../images/Vector1.png'
import vector2 from '../images/Vector-2.png'
import vector3 from '../images/Vector3.png'
import vector4 from '../images/Vector4.png'
import rico from '../images/rico.jpg'
import alex from '../images/alex.jpg'
import vaneet from '../images/vaneet.jpg'
import nick from '../images/nick.jpg'
import roman from '../images/roman.jpg'
import cedric from '../images/cedric.jpg'
import tom from '../images/tom.jpeg'
import Touch from './Touch'

const Testimonial = () => {
    return (
        <div>
            <section className='test'>
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="test-img">
                                <img src={test} alt='test' />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="dots">
                                <div className="test-last">
                                    <div className="test-heading">
                                        <h2>TESTIMONIAL</h2>
                                    </div>
                                    <div className="test-data1">
                                        <p>We continuously improve ourselves to deliver the best service to our customers. We have implemented the most effective way to improve our services by listening to customers’ views and feedback.</p>

                                    </div>
                                    <div className="test-data2">
                                        <p>We are working hard to highly satisfy our clients and see the smile on their faces. The testimonials are given below result of our hard work and client-centric approach.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className='container'>
                <OwlCarousel className='owl-theme' responsive={{ '0': { items: 1, nav: false }, '768': { items: 2, nav: false }, '990': { nav: true }, }} loop margin={10} nav>
                    <div className="card card-1" >
                        <div className='quote'>
                            <img src={quote1} alt='quote1' />
                        </div>
                        <div class='item'>
                            <h4>Their communication and project management skills were exceptional, keeping us informed and involved at every stage of the process. What truly set them apart was their innovative approach to problem-solving and their ability to
                                translate our vision into a cutting-edge solution</h4>
                        </div>
                        <div className='vector1'>
                            <img src={vector1} alt='vector1' />
                        </div>
                        <div className='user'>
                            <img src={rico} alt='rico' />
                            <h3>Rico</h3>
                        </div>
                    </div>

                    <div className="card card-2" >
                        <div className='quote'>
                            <img src={quote2} alt='quote2' />
                        </div>
                        <div class='item'>
                            <h4>Kalathiya Infotech IT Solutions exceeded my expectations in every aspect of our collaboration.
                                From the initial consultation to the final delivery, their team demonstrated unparalleled
                                technical expertise, professionalism, and commitment to excellence.</h4>
                        </div>
                        <div className='vector2'>
                            <img src={vector2} alt='vector2' />
                        </div>
                        <div className='user'>
                            <img src={alex} alt='alex' />
                            <h3>Alex</h3>
                        </div>
                    </div>

                    <div className="card card-3" >
                        <div className='quote'>
                            <img src={quote3} alt='quote3' />
                        </div>
                        <div class='item'>
                            <h4>Excellent Team. Teamwork isn't just a core value—it's the cornerstone of our success.
                                From the first day I joined the team, I was struck by the seamless collaboration and camaraderie
                                among my colleagues. Regardless of department or seniority, everyone works together towards a common goal:
                                delivering exceptional solutions to our clients.</h4>
                        </div>
                        <div className='vector3'>
                            <img src={vector3} alt='vector3' />
                        </div>
                        <div className='user'>
                            <img src={vaneet} alt='vaneet' />
                            <h3>Vaneet</h3>
                        </div>
                    </div>
                    <div className="card card-4" >
                        <div className='quote'>
                            <img src={quote4} alt='quote4' />
                        </div>
                        <div class='item'>
                            <h4>Completing our Android development project on time , IT Solutions was
                                a testament to their exceptional dedication and professionalism. From the outset,
                                their team exhibited a clear understanding of our project requirements and a commitment
                                to meeting our deadlines.</h4>
                        </div>
                        <div className='vector4'>
                            <img src={vector4} alt='vector4' />

                        </div>
                        <div className='user'>
                            <img src={nick} alt='nick' />
                            <h3>Nick</h3>
                        </div>

                    </div>

                    <div className="card card-1" >
                        <div className='quote'>
                            <img src={quote1} alt='quote1' />
                        </div>
                        <div class='item'>
                            <h4>Kalathiya team did an excellent job! They quickly
                                understood the tasks and worked very hard to meet all
                                requirements within time and budget. They are very capable
                                full stack developer, highly recommended!</h4>
                        </div>
                        <div className='vector1'>
                            <img src={vector1} alt='vector1' />
                        </div>
                        <div className='user'>
                            <img src={roman} alt='roman' />
                            <h3>Roman</h3>
                        </div>

                    </div>
                    <div className="card card-2" >
                        <div className='quote'>
                            <img src={quote2} alt='quote2' />
                        </div>
                        <div class='item'>
                            <h4>An absolute pleasure to work with this professional team, very easy to communicate with and very very knowlegable, look forward to working with you again soon.</h4>
                        </div>
                        <div className='vector2'>
                            <img src={vector2} alt='vector2' />
                        </div>
                        <div className='user'>
                            <img src={cedric} alt='cedric' />
                            <h3>Cedric</h3>
                        </div>
                    </div>
                    <div className="card card-3" >
                        <div className='quote'>
                            <img src={quote3} alt='quote3' />
                        </div>
                        <div class='item'>
                            <h4>I had the pleasure of working with Kalathiya Infotech for my company's website development project, and I must say they exceeded my expectations.</h4>
                        </div>
                        <div className='vector3'>
                            <img src={vector3} alt='vector3' />
                        </div>
                        <div className='user'>
                            <img src={tom} alt='tom' />
                            <h3>Tom</h3>
                        </div>
                    </div>

                </OwlCarousel>
            </section>

            <Touch />

            <Footer />

        </div >
    )
}

export default Testimonial
