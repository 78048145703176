import React from "react";
import about from "../images/About kalathiya.png";
import underline from "../images/underline.png";
import group36 from "../images/Group-36.png";
import group37 from "../images/Group-37.png";
import group38 from "../images/Group-38.png";
import group49 from "../images/Group-49.png";
import group50 from "../images/Group-50.png";
import group51 from "../images/Group-51.png";
import group52 from "../images/Group-52.png";
import group53 from "../images/Group-53.png";
import group54 from "../images/Group-54.png";
import group69_1 from "../images/Group-69 (1).png";
import group70_1 from "../images/Group-70(1).png";
import group71 from "../images/Group-71.png";
import aboutcareer from "../images/TEAM WORK.png"
import "../css/media.css";
import Footer from "./Footer";

const About = () => {
  return (
    <div>
      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="about-img">
                <img src={about} alt="about" />
              </div>
            </div>
            <div className="col-6">
              <div className="dots">
                <div className="about-last">
                  <div className="about-heading">
                    <h2>ABOUT KALATHIYA</h2>
                  </div>
                  <div className="about-data">
                    <p>
                      Kalathiya Infotech is a amazing path of development and
                      services company. With our comprehensive range of
                      services, cutting-edge technologies, and a team of skilled
                      experts, we empower our clients to achieve their goals and
                      elevate their online presence.
                    </p>
                    <p className="mt-3">Our team of skilled professionals brings together a wealth of experience and knowledge to deliver top-notch services in software development, cybersecurity, cloud computing, and beyond. At Kalathiya Infotech we strive for nothing less than perfection, working tirelessly to ensure our clients stay ahead in an ever-evolving digital landscape.</p>
                    <p className="mt-3">Our diverse team of seasoned professionals brings together a wealth of experience and expertise across various domains, including software development, cybersecurity, cloud computing, and more. We pride ourselves on our ability to deliver scalable and sustainable solutions that drive tangible results for businesses of all sizes. At Kalathiya infotech we believe in harnessing the power of technology to empower our clients to thrive in an increasingly interconnected world. Discover the difference and embark on a journey towards digital transformation today.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-infotech">
        <div className="container">
          <div className="about-infotech-data">
            <h2>ABOUT KALATHIYA INFOTECH</h2>
            <img className="underline" src={underline} alt="underline" />
            <p></p>
          </div>
          <div className="row about-infotech-row mt-4">
            <div className="col-4 about-infotech-col-4">
              <div className="card">
                <div className="img">
                  <img src={group36} className="card-img-top" alt="" />
                </div>
                <div className="card-body">
                  <h5 className="card-title">PEOPLE</h5>
                  <p className="card-text">
                    We perpetuate a positive sparkling surrounding, that can
                    encourage growth and strenghtens working bond and growth.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4 about-infotech-col-4">
              <div className="card">
                <div className="img">
                  <img src={group37} className="card-img-top" alt="" />
                </div>
                <div className="card-body">
                  <h5 className="card-title">INTEGRITY</h5>
                  <p className="card-text">
                    We hold ourselves to the highest ethical standards,
                    consistently demonstrating honesty, transparency, and
                    accountability in all that we do.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4 about-infotech-col-4">
              <div className="card">
                <div className="img">
                  <img src={group38} className="card-img-top" alt="" />
                </div>
                <div className="card-body">
                  <h5 className="card-title">SUCCESS</h5>
                  <p className="card-text">
                    Success is not just measured by financial achievements, but
                    by the positive impact we make on our clients, employees,
                    and communities
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-choose">
        <div className="container">
          <div className="about-choose-data">
            <h2>Why should you choose KALATHIYA INFOTECH?</h2>
            <img className="underline" src={underline} alt="underline" />
            <p>
              Choose KALATHIYA for unparalleled expertise, exceptional quality,
              and unwavering commitment to your success. With a proven track
              record of delivering innovative solutions tailored to your needs,
              we prioritize your satisfaction above all else. Join us and
              experience the difference of working with a dedicated partner who
              goes above and beyond to exceed your expectations.
            </p>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="card">
                <img src={group49} className="card-img-top" alt="" />
                <div className="card-body">
                  <h5 className="card-title">Expertise in multiple domains</h5>
                  <p className="card-text">
                    We have boasts expertise across diverse domains, ranging
                    from software development and data analytics to
                    cybersecurity and cloud computing. With a versatile team of
                    professionals, we offer comprehensive solutions tailored to
                    meet the unique needs of each project.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card">
                <img src={group50} className="card-img-top" alt="" />
                <div className="card-body">
                  <h5 className="card-title">
                    Intellectual Property Rights Protection
                  </h5>
                  <p className="card-text">
                    Ensuring the safeguarding of intellectual property rights,
                    we prioritize confidentiality and employ robust security
                    measures, fortifying trust and protecting innovation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card">
                <img src={group51} className="card-img-top" alt="" />
                <div className="card-body">
                  <h5 className="card-title">
                    Highly Experienced and Productive Professionals
                  </h5>
                  <p className="card-text">
                    Our member of workforce are expert in the IT industry and
                    have various experience in different languages. Morever we
                    have professional and exclusive resources to provide
                    competitive solutions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card">
                <img src={group52} className="card-img-top" alt="" />
                <div className="card-body">
                  <h5 className="card-title">Reporting Structure</h5>
                  <p className="card-text">
                    We use many project management related tools to manage
                    project work, time, planning, desigining and execution. Main
                    point of using this tools is to provide daily , weekly and
                    monthly reports.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card">
                <img src={group53} className="card-img-top" alt="" />
                <div className="card-body">
                  <h5 className="card-title">
                    The Proper Hierarchy and Escalation Path
                  </h5>
                  <p className="card-text">
                    We believe in clear hierarchy and escalation path ensures
                    streamlined communication a nd effective decision-making
                    within the team. By defining roles and responsibilities,
                    addressing issues promptly, and escalating concerns as
                    needed.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card">
                <img src={group54} className="card-img-top" alt="" />
                <div className="card-body">
                  <h5 className="card-title">24x7 Support</h5>
                  <p className="card-text">
                    We don't disappoint our client any time. Once you shared
                    your problem we would not stop till is is clear and client
                    is satisfied. We provide 24*7 support for everyone
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="card3">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <div className="card card-border-one">
                <img src={group69_1} className="card-img-top" alt="" />
                <div className="card-body">
                  <h5 className="card-title card-first">Our Vision​</h5>
                  <p className="card-text">
                    Our vision is to lead the way in revolutionizing technology
                    for positive change and limitless possibilities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="card card-border-two">
                <img src={group70_1} className="card-img-top" alt="" />
                <div className="card-body">
                  <h5 className="card-title card-second">Our Mission</h5>
                  <p className="card-text">
                    Our mission is to convert imagination into actuality by
                    providing our team with a positive work area and latest
                    technology
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="card card-border-three position-relative">
                <img src={group71} className="card-img-top" alt="" />
                <div className="card-body">
                  <h5 className="card-title card-third">Core Value</h5>
                  <p className="card-text">
                    We hand over an effective results for our clients by
                    learning from the past and collaborating on change for the
                    future.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-career">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="career">
                <div className="about-career-last">
                  <div className="about-career-heading">
                    <h2>CAREER</h2>
                  </div>
                  <div className="about-career-data">
                    <p>
                      Teamwork is the force behind every successful business.
                    </p>
                  </div>
                  {/* <div className="about-button">
                    <button className="btn about-career-button">
                      JOIN OUR TEAM
                    </button>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="about-img">
                <img className="about-image" src={aboutcareer} alt="aboutcareer" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
