import React from 'react'
import '../css/media.css'
import Footer from './Footer'
import careerskill from '../images/career-skill.png'
import underline from '../images/underline.png'
import workplace from '../images/workplace.png'
import benefit from '../images/Benefits.png'
import { faCode, faEnvelope, faLocationDot, faPhone, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReCAPTCHA from 'react-google-recaptcha'

const Career = () => {
    return (
        <div>
            <div className="career-skill">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="career-skill-last">
                                <div className="career-skill-heading">
                                    <h2>BECOME A PART OF THE KALATHIYA FAMILY</h2>
                                </div>
                                <div className="career-skill-data1">
                                    <p>Joining kalathiya Infotech could be your life-changing decision, so do so with endless goodies on the way. It is a home away from home, where we work as a team for a common goal – to evolve constantly with the latest technologies while taking the utmost care of our most valuable assets, our employees. Experience the ‘Feel Good factor’ and the value of ‘Work-life balance by working with us.</p>
                                </div>
                                <div className="career-skill-data2">
                                    <p>Teamwork makes the dream work!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="career-skill-img">
                                <img src={careerskill} alt='career-skill' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className='career-mail'>
                <div className="container">
                    <div className="career-mail-data">
                        <h3>Let's engineer a new future together.</h3>
                        <img className="underline" src={underline} alt="underline" />
                        <p>Ready for Growth? </p>
                        <span> In today's rapidly evolving digital landscape, staying ahead of the curve is essential for sustained growth. Our comprehensive suite of IT solutions empowers businesses to scale efficiently, adapt quickly, and thrive in an ever-changing market. From cloud computing and data analytics to cybersecurity and software development, we provide the tools and expertise needed to propel your organization forward. </span>
                    </div>
                </div>
            </section>

            <section className="join">
                <div className="container">
                    <div className="join-data">
                        <h3>Why Join Kalathiya Infotech?</h3>
                        <img className="underline" src={underline} alt="underline" />
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="join-head">
                                <h2>5 Days Working</h2>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="join-head">
                                <h2>Flexibility in timing</h2>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="join-head">
                                <h2>Supportive staff</h2>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="color1">
                                <div className="join-head-main1">
                                    <h2>Supportive staff</h2>
                                </div>
                                <div className="join-body">
                                    <p>Our dedicated and knowledgeable staff are committed to providing exceptional support to our clients every step of the way.  </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="color2">
                                <div className="join-head-main2">
                                    <h2>State-of-the-art Infrastructure</h2>
                                </div>
                                <div className="join-body">
                                    <p>We have ensured our employees have access to the best digital and physical infrastructure to help them deliver the best!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="color3">
                                <div className="join-head-main3">
                                    <h2>Vibrant Environment</h2>
                                </div>
                                <div className="join-body">
                                    <p>At our workplace we try to create a healthy and positive
                                        environment that brings employees to come up with best results.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="color4">
                                <div className="join-head-main4">
                                    <h2>Career Growth</h2>
                                </div>
                                <div className="join-body">
                                    <p>Embark on a rewarding journey with us, where your potential meets limitless possibilities in the IT.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="color5">
                                <div className="join-head-main5">
                                    <h2>State-of-the-art Infrastructure</h2>
                                </div>
                                <div className="join-body">
                                    <p>We give more credit to have an open and free
                                        communication with all th working staff.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="color6">
                                <div className="join-head-main6">
                                    <h2>HR Best Practices</h2>
                                </div>
                                <div className="join-body">
                                    <p>We offer our employees a project-based corporate structure to ensure they are highly motivated!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="workplace">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <img src={workplace} alt="workplace" />
                        </div>
                        <div className="col-6">
                            <div className="workplace-data">
                                <h2>OUR WORKPLACE</h2>
                                <img className="underline" src={underline} alt="underline" />
                                <p>At Kalathiya Infotech, we believe that a positive workplace culture is the foundation for success. Our vibrant and collaborative environment fosters creativity, innovation, and continuous learning.</p>
                                <p>From open communication channels to regular team-building activities, we prioritize creating a supportive and inclusive atmosphere where every voice is heard and valued. </p>

                                <p>Our state-of-the-art facilities and flexible work arrangements ensure that our employees have the tools and resources they need to thrive. </p>

                                <p>Whether you're a seasoned professional or just starting your career in IT, you'll find ample opportunities for growth and development with us.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="benefit">
                <div className="container">
                    <div className="benefit-data">
                        <h3>BENEFITS</h3>
                        <img className="underline" src={underline} alt="underline" />
                        <p>At Kalathiya Infotech, we encourage a good work-life. We strongly believe to reached success with the growth of our team. We oragnize seminears and meeting to know each others personal life more likely.</p>
                    </div>
                    <div className="benefit-img">
                        <img src={benefit} alt="benefit" />
                    </div>
                </div>
            </section>

            <section className="kindly">
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <h2>Feel Free to Contact Us</h2>
                            <p className='first'>HR Inquiry</p>
                            <div className="kindly-icon">
                                <FontAwesomeIcon className='i' icon={faEnvelope} />
                                <p>Info@kalathiyainfotech.com</p>
                            </div>
                            <div className="kindly-icon">
                                <FontAwesomeIcon className='i' icon={faPhone} />
                                <p>+91 79902 84173</p>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="border">
                                <div>
                                    <div className="kindly-head">
                                        <h3>Kindly e-mail your CV</h3>
                                    </div>
                                    <div className="kindly-body">
                                        <div className="mb-3 d-flex flex-column">
                                            <div className="d-flex">
                                                <div style={{ flex: 1 }}>
                                                    <label htmlFor="exampleFormControlInput1">First Name*</label>
                                                    <input type="text" className="form-control name" id="exampleFormControlInput1" placeholder="Name" />
                                                </div>
                                                <div style={{ flex: 1, marginLeft: '1rem' }}>
                                                    <label htmlFor="exampleFormControlInput2">Last Name*</label>
                                                    <input type="text" className="form-control name" id="exampleFormControlInput2" placeholder="Last Name" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-3 d-flex flex-column">
                                            <div className="d-flex">
                                                <div style={{ flex: 1 }}>
                                                    <label>Email*</label>
                                                    <input type="email" className="form-control email" id="exampleFormControlInput1" placeholder="Email Id" />
                                                </div>
                                                <div style={{ flex: 1, marginLeft: '1rem' }}>
                                                    <label>Mobile No*</label>
                                                    <input type="text" className="form-control no" id="exampleFormControlInput1" placeholder="Mobile Number" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-3 d-flex flex-column">
                                            <label>Upload CV*</label>
                                            <input type="file" />
                                        </div>

                                        <div className="button mb-3 d-flex">
                                            <div className='margin' style={{ marginRight: 'auto' }}>
                                                <ReCAPTCHA
                                                    sitekey={'6LeU7rMpAAAAAFlcq0q5YzBo-HdadiPaA7UOU3K7'}
                                                />
                                            </div>
                                            <button className='btn-contact'>
                                                SEND MESSAGE
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="current-job-openings">
                <div className="container">
                    <div className="current-job-data">
                        <div className="current-job-data-top">
                            <h2>OUR CORE SERVICES</h2>
                            <img className="underline" src={underline} alt="underline" />
                            <p>
                                We always look for sparkling talents  Here are
                                some of the job openings currently available at The Kalathiya
                                Infotech. If you believe you’re appropriate to be a member of our team , contact us today!
                            </p>
                            <p>
                                To apply for any of the below positions, email your updated CV
                                to <a href="#">Info@kalathiyainfotech.com</a>
                            </p>
                        </div>
                        <div className="current-job-data-bottom">
                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseOne"
                                            aria-expanded="true"
                                            aria-controls="panelsStayOpen-collapseOne"
                                        >
                                            <div className="row all-data-row">
                                                <div className="col-9 manu-col-9">
                                                    <div className="row title-row">
                                                        <div className="col title-p">
                                                            <p>React.js & Node.js</p>
                                                        </div>
                                                    </div>
                                                    <div className="row manu-all-row">
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2 icon-col">
                                                                    <FontAwesomeIcon icon={faLocationDot} />
                                                                </div>
                                                                <div className="col-10 icon-col">
                                                                    <p className="Location">Location</p>
                                                                    <p>Mota Varachha / Surat</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faCode} />
                                                                </div>
                                                                <div className="col-10">
                                                                    <p className="Location">Experience</p>
                                                                    <p>1 to 4 Years</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faUser} />
                                                                </div>
                                                                <div className="col-10">
                                                                    <p className="Location">Position</p>
                                                                    <p>2</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3 button-view">
                                                    <button className="btn btn-outline-success">
                                                        View Details
                                                    </button>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseOne"
                                        className="accordion-collapse collapse show"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-9">
                                                    <h3 className="accordion-title">Skills Required:</h3>
                                                    <ul>
                                                        <li className="accordion-li">
                                                            React.js & Node.js both technologies knowledge
                                                            required.
                                                        </li>
                                                        <li className="accordion-li">
                                                            Good English communication skill
                                                        </li>
                                                        <li className="accordion-li">
                                                            Min. Bachelor Degree (Computer Field)
                                                        </li>
                                                        <li className="accordion-li">Team Player</li>
                                                    </ul>
                                                </div>
                                                <div className="col-3 apply">
                                                    <button className="btn btn-success button-apply">
                                                        Apply Now
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-collapseTwo"
                                        >
                                            <div className="row all-data-row">
                                                <div className="col-9 manu-col-9">
                                                    <div className="row title-row">
                                                        <div className="col title-p">
                                                            <p>Laravel & Vue.JS</p>
                                                        </div>
                                                    </div>
                                                    <div className="row manu-all-row">
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faLocationDot} />
                                                                </div>
                                                                <div className="col-10">
                                                                    <p className="Location">Location</p>
                                                                    <p>Mota Varachha / Surat</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faCode} />
                                                                </div>
                                                                <div className="col-10">
                                                                    <p className="Location">Experience</p>
                                                                    <p>1 year or above</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faUser} />
                                                                </div>
                                                                <div className="col-10">
                                                                    <p className="Location">Position</p>
                                                                    <p>1</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3 button-view">
                                                    <button className="btn btn-outline-success">
                                                        View Details
                                                    </button>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseTwo"
                                        className="accordion-collapse collapse"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-9">
                                                    <h3 className="accordion-title">Skills Required:</h3>
                                                    <ul>
                                                        <li className="accordion-li">
                                                            Laravel & Vue.js both Technologies knowledge
                                                            required.
                                                        </li>
                                                        <li className="accordion-li">
                                                            Good English communication skill
                                                        </li>
                                                        <li className="accordion-li">
                                                            Min. Bachelor Degree (Computer Field)
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-3 apply">
                                                    <button className="btn btn-success button-apply">
                                                        Apply Now
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseThree"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-collapseThree"
                                        >
                                            <div className="row all-data-row">
                                                <div className="col-9 manu-col-9">
                                                    <div className="row title-row">
                                                        <div className="col title-p">
                                                            <p>Codeigniter & Laravel</p>
                                                        </div>
                                                    </div>
                                                    <div className="row manu-all-row">
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faLocationDot} />
                                                                </div>
                                                                <div className="col-10">
                                                                    <p className="Location">Location</p>
                                                                    <p>Mota Varachha / Surat</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faCode} />
                                                                </div>
                                                                <div className="col-10">
                                                                    <p className="Location">Experience</p>
                                                                    <p>1 to 4 Years</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faUser} />
                                                                </div>
                                                                <div className="col-10">
                                                                    <p className="Location">Position</p>
                                                                    <p>2</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3 button-view">
                                                    <button className="btn btn-outline-success">
                                                        View Details
                                                    </button>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseThree"
                                        className="accordion-collapse collapse"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-9">
                                                    <h3 className="accordion-title">Skills Required:</h3>
                                                    <ul>
                                                        <li className="accordion-li">
                                                            Must know the MVC Framework. (Laravel &
                                                            Codeigniter).
                                                        </li>
                                                        <li className="accordion-li">
                                                            Good English communication skill
                                                        </li>
                                                        <li className="accordion-li">
                                                            Min. Bachelor Degree (Computer Field)
                                                        </li>
                                                        <li className="accordion-li">Team Player</li>
                                                    </ul>
                                                </div>
                                                <div className="col-3 apply">
                                                    <button className="btn btn-success button-apply">
                                                        Apply Now
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseFour"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-collapseFour"
                                        >
                                            <div className="row all-data-row">
                                                <div className="col-9 manu-col-9">
                                                    <div className="row title-row">
                                                        <div className="col title-p">
                                                            <p>PHP Team Leader</p>
                                                        </div>
                                                    </div>
                                                    <div className="row manu-all-row">
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faLocationDot} />
                                                                </div>
                                                                <div className="col-10">
                                                                    <p className="Location">Location</p>
                                                                    <p>Mota Varachha / Surat</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faCode} />
                                                                </div>
                                                                <div className="col-10">
                                                                    <p className="Location">Experience</p>
                                                                    <p>3 year or above</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faUser} />
                                                                </div>
                                                                <div className="col-10">
                                                                    <p className="Location">Position</p>
                                                                    <p>2</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3 button-view">
                                                    <button className="btn btn-outline-success">
                                                        View Details
                                                    </button>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseFour"
                                        className="accordion-collapse collapse"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-9">
                                                    <h3 className="accordion-title">Skills Required:</h3>
                                                    <ul>
                                                        <li className="accordion-li">
                                                            Technical Proficiency: In-depth knowledge of the
                                                            MVC framework being used (Ex. CodeIgniter or
                                                            Laravel).
                                                        </li>
                                                        <li className="accordion-li">
                                                            Project Planning and Management: Good Requirement
                                                            gathering skills.
                                                        </li>
                                                        <li className="accordion-li">
                                                            Team Leadership: Providing guidance, mentorship,
                                                            and support to team members with Positive team
                                                            culture.
                                                        </li>
                                                        <li className="accordion-li">
                                                            Communication Skills: Verbal and Written.
                                                        </li>
                                                        <li className="accordion-li">
                                                            Encouraging continuous learning and skill
                                                            development within the team.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-3 apply">
                                                    <button className="btn btn-success button-apply">
                                                        Apply Now
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseFive"
                                            aria-expanded="false"
                                            aria-controls="panelsStayOpen-collapseFive"
                                        >
                                            <div className="row all-data-row">
                                                <div className="col-9 manu-col-9">
                                                    <div className="row title-row">
                                                        <div className="col title-p">
                                                            <p>Flutter Developer</p>
                                                        </div>
                                                    </div>
                                                    <div className="row manu-all-row">
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faLocationDot} />
                                                                </div>
                                                                <div className="col-10">
                                                                    <p className="Location">Location</p>
                                                                    <p>Mota Varachha / Surat</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faCode} />
                                                                </div>
                                                                <div className="col-10">
                                                                    <p className="Location">Experience</p>
                                                                    <p>2 year or above</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-4 manu-col-4">
                                                            <div className="row menu-all-col">
                                                                <div className="col-2">
                                                                    <FontAwesomeIcon icon={faUser} />
                                                                </div>
                                                                <div className="col-10">
                                                                    <p className="Location">Position</p>
                                                                    <p>2</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3 button-view">
                                                    <button className="btn btn-outline-success">
                                                        View Details
                                                    </button>
                                                </div>
                                            </div>
                                        </button>
                                    </h2>
                                    <div
                                        id="panelsStayOpen-collapseFive"
                                        className="accordion-collapse collapse"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-9">
                                                    <h3 className="accordion-title">Skills Required:</h3>
                                                    <ul>
                                                        <li className="accordion-li">
                                                            Knowledge of Flutter, Dart, Firebase and so on.
                                                        </li>
                                                        <li className="accordion-li">
                                                            Good English Communication Skill.
                                                        </li>
                                                        <li className="accordion-li">
                                                            Min. Bachelor Degree (Computer Field)
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-3 apply">
                                                    <button className="btn btn-success button-apply">
                                                        Apply Now
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row"></div>
                </div>
            </section>

            <Footer />

        </div >
    )
}

export default Career
