import React from "react";
import "../css/media.css";
import underline from "../images/underline.png";
import reactnative from "../images/React Native.png";
import Group_43 from "../images/Group-43.png";
import Group_44 from "../images/Group-44.png";
import Group_45 from "../images/Group-45.png";
import Group_46 from "../images/Group-46.png";
import Group_47 from "../images/Group-47.png";
import Group_48 from "../images/Group-48.png";
import react_native_features from "../images/react-native-features.png";
import Group_43_2 from "../images/Group-43-2.png";
import Group_44_2 from "../images/Group-44-2.png";
import Group_45_1 from "../images/Group-45-1.png";
import Group_47_1 from "../images/Group-47-1.png";
import Group_46_1 from "../images/Group-46-1.png";
import Group_48_1 from "../images/Group-48-1.png";
import Group_49_1 from "../images/Group-49-1.png";
import Group_50_1 from "../images/Group-50-1.png";
import Group_51_1 from "../images/Group-51-1.png";
import Group_52_1 from "../images/Group-52-1.png";
import Group_53_1 from "../images/Group-53-1.png";
import Group_54_1 from "../images/Group-54-1.png";
import Touch from "./Touch";
import Footer from "./Footer";

const React_native = () => {
  return (
    <div>
      {/* <section className="We-Build-all">
        <div className="We-Build">
          <div className="container">
            <div className="row">
              <div className="col-6 we-build_text px-5">
                <div className="we-data">
                  <div className="top-data">
                    <h2 className="software">REACT NATIVE DEVELOPMENT</h2>
                  </div>
                  <div className="bottom-data">
                    <p>
                      Kalathiya Infotech specializes in crafting cutting-edge
                      web solutions tailored to meet the unique demands of
                      businesses. Our innovative approach enables us to
                      effectively address complex challenges in both B2B and B2C
                      environments. We excel in developing user-friendly
                      interfaces, seamless user experiences, and resilient web
                      technologies that empower our clients to thrive in today's
                      dynamic digital landscape.
                    </p>
                    <p className="mt-3">We streamline the development process without compromising on performance or user experience. Our team of experienced developers leverages the flexibility and efficiency of React Native to build immersive and feature-rich mobile apps that cater to diverse audiences. Whether you're targeting iOS, Android, or both, we're dedicated to delivering seamless user experiences, rapid development cycles, and cost-effective solutions.</p>
                  </div>
                </div>
              </div>
              <div className="col-6 we-build_img">
                <img
                  src={reactnative}
                  alt="reactnative"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className='mobile'>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="mobile-first">
                <div className="mobile-heading">
                  <h2>REACT NATIVE DEVELOPMENT</h2>
                </div>
                <div className="mobile-data">
                  <p>
                    Kalathiya Infotech specializes in crafting cutting-edge
                    web solutions tailored to meet the unique demands of
                    businesses. Our innovative approach enables us to
                    effectively address complex challenges in both B2B and B2C
                    environments. We excel in developing user-friendly
                    interfaces, seamless user experiences, and resilient web
                    technologies that empower our clients to thrive in today's
                    dynamic digital landscape.
                  </p>
                  <p className="mt-3">We streamline the development process without compromising on performance or user experience. Our team of experienced developers leverages the flexibility and efficiency of React Native to build immersive and feature-rich mobile apps that cater to diverse audiences. Whether you're targeting iOS, Android, or both, we're dedicated to delivering seamless user experiences, rapid development cycles, and cost-effective solutions.</p>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="mobile-img">
                <img
                  src={reactnative}
                  alt="reactnative"
                />
              </div>
            </div>
          </div>
        </div>

      </section>



      <section className="web-application-development-services">
        <div className="container">
          <div className="web-application-services-data">
            <div className="web-application-services-data-top">
              <h2>REACT NATIVE SERVICES</h2>
              <img className="underline" src={underline} alt="underline" />
              <p>
                Kalathiya Infotech is a highly esteemed provider of website
                development services, serving diverse industries with our
                tailored and personalized approach.
              </p>
            </div>
            <div className="web-application-services-data-bottom">
              <div className="row web-application-card-row">
                <div className="col-4 web-application-card-col-4">
                  <div className="custom-application-border-card">
                    <div className="row web-application-row-img">
                      <div className="col-3 web-application-col-6-img">
                        <img src={Group_43} alt="Group_43" />
                      </div>
                      <div className="col-9 web-application-col-6-data-h3">
                        <h3>Custom Web Development</h3>
                      </div>
                    </div>
                    <p className="web-application-col-6-p">
                      To create a custom element with React, you need to create
                      a new React component and then wrap it in a JavaScript
                      class that extends HTMLElement.
                    </p>
                  </div>
                </div>
                <div className="col-4 web-application-card-col-4">
                  <div className="portal-application-border-card">
                    <div className="row web-application-row-img">
                      <div className="col-3 web-application-col-6-img">
                        <img src={Group_44} alt="Group_43" />
                      </div>
                      <div className="col-9 web-application-col-6-data-h3">
                        <h3>Web Portal Development</h3>
                      </div>
                    </div>
                    <p className="web-application-col-6-p">
                      Developing web portals using React offers a dynamic and
                      modern approach to creating interactive and feature-rich
                      online platforms. We design and build web portals that
                      deliver a seamless user experience across devices.
                    </p>
                  </div>
                </div>
                <div className="col-4 web-application-card-col-4">
                  <div className="commerce-application-border-card">
                    <div className="row web-application-row-img">
                      <div className="col-3 web-application-col-6-img">
                        <img src={Group_45} alt="Group_43" />
                      </div>
                      <div className="col-9 web-application-col-6-data-h3">
                        <h3>E-commerce Development</h3>
                      </div>
                    </div>
                    <p className="web-application-col-6-p">
                      Building e-commerce applications with React Native offers
                      a robust and versatile solution for businesses looking to
                      establish a strong online presence.
                    </p>
                  </div>
                </div>
                <div className="col-4 web-application-card-col-4">
                  <div className="integration-application-border-card">
                    <div className="row web-application-row-img">
                      <div className="col-3 web-application-col-6-img">
                        <img src={Group_46} alt="Group_46" />
                      </div>
                      <div className="col-9 web-application-col-6-data-h3">
                        <h3>Web Open Source Integration and Customization</h3>
                      </div>
                    </div>
                    <p className="web-application-col-6-p">
                      Integrating and customizing open-source solutions within
                      React Native environments empowers businesses to
                      flexibility and scalability of these frameworks while
                      tailoring them to their specific needs.
                    </p>
                  </div>
                </div>
                <div className="col-4 web-application-card-col-4">
                  <div className="mobile-application-border-card">
                    <div className="row web-application-row-img">
                      <div className="col-3 web-application-col-6-img">
                        <img src={Group_47} alt="Group_47" />
                      </div>
                      <div className="col-9 web-application-col-6-data-h3">
                        <h3>Mobile-Friendly Website Development</h3>
                      </div>
                    </div>
                    <p className="web-application-col-6-p">
                      Our team of skilled developers utilizes React Native's
                      component-based architecture to ensure optimal performance
                      and compatibility across different platforms.
                    </p>
                  </div>
                </div>
                <div className="col-4 web-application-card-col-4">
                  <div className="flexible-application-border-card">
                    <div className="row web-application-row-img">
                      <div className="col-3 web-application-col-6-img">
                        <img src={Group_48} alt="Group_48" />
                      </div>
                      <div className="col-9 web-application-col-6-data-h3">
                        <h3>Flexible Web Maintenance Custom Web Development</h3>
                      </div>
                    </div>
                    <p className="web-application-col-6-p">
                      Our expertise in React Native allows us to provide ongoing
                      maintenance and support for existing web applications,
                      ensuring they remain secure, up-to-date, and optimized for
                      performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features-of-Website-development ">
        <div className="container">
          <div className="row features-of-Website-row">
            <div className="col-6 features-of-Website-col-6">
              <div className="features-of-Website-data">
                <div className="features-of-h3">
                  <h3 className="features-top-h3">Features of</h3>
                  <h3 className="features-bottom-h3">React Native</h3>
                </div>
                <div className="features-of-p">
                  <p>
                    By keeping your budget in mind, our expert developers assist
                    you in building an excellent website and maintaining it
                    regularly.
                  </p>
                </div>
                <div className="features-of-ul">
                  <ul>
                    <li>Proper understanding of client’s requirement</li>
                    <li>User – Friendly content</li>
                    <li>Adequate structure of a website</li>
                    <li>Friendly admin page for marketing purposes</li>
                    <li>A checkout page</li>
                    <li>Security</li>
                    <li>Required plugins for backend purpose</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-6 features-image">
              <div className="features-of-Website-img">
                <img src={react_native_features} alt="Website_Development" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="get-a-free-mobile">
        <div className="get-a-free-mobile-all">
          <div className="container">
            <div className="row get-a-free-row">
              <div className="col-9 get-a-free-col-9">
                <div className="get-a-free-data">
                  <h3>Get a Free React Native consultation</h3>
                  <h2>with our experts today!</h2>
                </div>
              </div>
              <div className="col-3 get-a-free-col-3">
                <div className="get-a-free-button">
                  <a href="/contact">
                    <button className="btn btn-warning">CONTACT US</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-expertise">
        <div className="container">
          <div className="our-expertise-data-head">
            <div className="our-expertise-data-top-head">
              <h2>OUR CORE SERVICES</h2>
              <img className="underline" src={underline} alt="underline" />
            </div>
            <div className="our-core-data-bottom">
              <div className="row our-expertise-data-row">
                <div className="col-2 our-expertise-data-col-2">
                  <div className="education-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_43_2} alt="Group_43_2" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Education</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="health-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_44_2} alt="Group_44_2" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Health Care</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="commerce-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_45_1} alt="Group_45_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>E-commerce</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="fitness-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_47_1} alt="Group_47_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Fitness</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="lifestyle-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_46_1} alt="Group_46_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Lifestyle</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="transportation-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_48_1} alt="Group_48_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Transportation</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="fitness-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_49_1} alt="Group_49_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Real Estate</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="event-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_50_1} alt="Group_50_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Event Management</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="lifestyle-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_51_1} alt="Group_51_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Travel</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="education-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_52_1} alt="Group_52_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Finance</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="health-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_53_1} alt="Group_53_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Social Network</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="commerce-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_54_1} alt="Group_54_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Food & Drink</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Touch />
      <Footer />
    </div>
  );
};

export default React_native;
