import React from 'react'
import Digital1 from '../images/Digital1.jpg'
import underline from '../images/underline.png'
import group3 from '../images/Group-3.jpg'
import group4 from '../images/Group-4.jpg'
import group5 from '../images/Group-5.jpg'
import group6 from '../images/Group-6.jpg'
import group7 from '../images/Group-7.jpg'
import group8 from '../images/Group-8.jpg'
import DigitalMarketing from '../images/Digital-Marketing.png'
import Footer from './Footer'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import quote1 from '../images/Double-quote1.png'
import quote2 from '../images/Double-quote2.png'
import quote3 from '../images/Double-quote3.png'
import quote4 from '../images/Double-quote4.png'
import vector1 from '../images/Vector1.png'
import vector2 from '../images/Vector-2.png'
import vector3 from '../images/Vector3.png'
import vector4 from '../images/Vector4.png'
import rico from '../images/rico.jpg'
import alex from '../images/alex.jpg'
import vaneet from '../images/vaneet.jpg'
import nick from '../images/nick.jpg'
import roman from '../images/roman.jpg'
import cedric from '../images/cedric.jpg'
import tom from '../images/tom.jpeg'
import Group_43_2 from '../images/Group-43-2.png'
import Group_44_2 from '../images/Group-44-2.png'
import Group_45_1 from '../images/Group-45-1.png'
import Group_46_1 from '../images/Group-46-1.png'
import Group_47_1 from '../images/Group-47-1.png'
import Group_48_1 from '../images/Group-48-1.png'
import Group_49_1 from '../images/Group-49-1.png'
import Group_50_1 from '../images/Group-50-1.png'
import Group_51_1 from '../images/Group-51-1.png'
import Group_52_1 from '../images/Group-52-1.png'
import Group_53_1 from '../images/Group-53-1.png'
import Group_54_1 from '../images/Group-54-1.png'
import Touch from './Touch'

const Digital = () => {
    return (
        <div>
            <section className='mobile'>
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="mobile-img">
                                <img src={Digital1} alt='Digital1' />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mobile-first">
                                <div className="mobile-heading">
                                    <h2>DIGITAL MARKETING</h2>
                                </div>
                                <div className="mobile-data">
                                    <p>Digital marketing encompasses a diverse array of strategies aimed at most effective platforms to promote business and engaging with audiences in the digital realm. it contain Social Media, Search Engines, Email, Content Marketing, Camping, and more. digital marketing services Main objectives are to increase brand visibility, drive website traffic, boost conversion rates, and promote businesses.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="web-application-development-services">
                <div className="container">
                    <div className="web-application-services-data">
                        <div className="web-application-services-data-top">
                            <h2>DIGITAL MARKETING SERVICES​</h2>
                            <img className="underline" src={underline} alt="underline" />
                            <p>
                                Appgenix Infotech, a distinguished name in digital marketing, tailors strategic campaigns that propel businesses across diverse industries to online success through customized solutions.
                            </p>
                        </div>
                    </div>

                    <div className="web-application-services-data-bottom">
                        <div className="row web-application-card-row">
                            <div className="col-4 web-application-card-col-4">
                                <div className="custom-application-border-card">
                                    <div className="row web-application-row-img">
                                        <div className="col-3 web-application-col-6-img">
                                            <img src={group3} alt="group3" />
                                        </div>
                                        <div className="col-9 web-application-col-6-data-h3">
                                            <h3>SEO Essentials</h3>
                                        </div>
                                    </div>
                                    <p className="web-application-col-6-p">
                                        SEO Essentials covers the fundamental principles of Search Engine Optimization, focusing on techniques to improve a website’s visibility in search engine results.
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 web-application-card-col-4">
                                <div className="portal-application-border-card">
                                    <div className="row web-application-row-img">
                                        <div className="col-3 web-application-col-6-img">
                                            <img src={group4} alt="group4" />
                                        </div>
                                        <div className="col-9 web-application-col-6-data-h3">
                                            <h3>Content Strategies</h3>
                                        </div>
                                    </div>
                                    <p className="web-application-col-6-p">
                                        Content Strategies delve into the planning and creation of compelling and valuable content. It encompasses content marketing, storytelling & driving organic traffic.
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 web-application-card-col-4">
                                <div className="commerce-application-border-card">
                                    <div className="row web-application-row-img">
                                        <div className="col-3 web-application-col-6-img">
                                            <img src={group5} alt="group5" />
                                        </div>
                                        <div className="col-9 web-application-col-6-data-h3">
                                            <h3>Social Media Trends</h3>
                                        </div>
                                    </div>
                                    <p className="web-application-col-6-p">
                                        Social Media Trends explores the latest developments. It
                                        discusses advertising techniques, & strategies for the power
                                        of social media brand promotion & audience engagement.
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 web-application-card-col-4">
                                <div className="integration-application-border-card">
                                    <div className="row web-application-row-img">
                                        <div className="col-3 web-application-col-6-img">
                                            <img src={group6} alt="group6" />
                                        </div>
                                        <div className="col-9 web-application-col-6-data-h3">
                                            <h3>Email Marketing Tips</h3>
                                        </div>
                                    </div>
                                    <p className="web-application-col-6-p">
                                        Email Marketing Tips provide insights into crafting effective
                                        email campaigns. email design, automation, & practices for
                                        delivering engaging & conversion-focused email content.
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 web-application-card-col-4">
                                <div className="mobile-application-border-card">
                                    <div className="row web-application-row-img">
                                        <div className="col-3 web-application-col-6-img">
                                            <img src={group7} alt="group7" />
                                        </div>
                                        <div className="col-9 web-application-col-6-data-h3">
                                            <h3>Business Marketing</h3>
                                        </div>
                                    </div>
                                    <p className="web-application-col-6-p">
                                        Business Marketing delves into marketing strategies tailored for businesses, covering B2B (business-to-business) & B2C (business-to-consumer) marketing approaches.
                                    </p>
                                </div>
                            </div>
                            <div className="col-4 web-application-card-col-4">
                                <div className="flexible-application-border-card">
                                    <div className="row web-application-row-img">
                                        <div className="col-3 web-application-col-6-img">
                                            <img src={group8} alt="group8" />
                                        </div>
                                        <div className="col-9 web-application-col-6-data-h3">
                                            <h3>Data Analytics Insights</h3>
                                        </div>
                                    </div>
                                    <p className="web-application-col-6-p">
                                        Data analytics insights play a crucial role in modern marketing, exploring data-driven decision-making in marketing. It includes discussions on tools & techniques for collecting, analyzing, and interpreting data.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features-of-Website-development ">
                <div className="container">
                    <div className="row features-of-Website-row">
                        <div className="col-6 features-of-Website-col-6">
                            <div className="features-of-Website-data">
                                <div className="features-of-h3">
                                    <h3 className="features-top-h3">Features of</h3>
                                    <h3 className="features-bottom-h3">Digital Marketing</h3>
                                </div>
                                <div className="features-of-p">
                                    <p>
                                        Digital marketing encompasses a range of features aimed at promoting products,
                                        services, or brands in the online landscape. It includes diverse strategies such as
                                        social media advertising, email campaigns, and pay-per-click advertising.
                                    </p>
                                </div>
                                <div className="features-of-ul">
                                    <ul>
                                        <li>Non-intrusive and Direct Method.</li>
                                        <li>Interactive Communication.</li>
                                        <li>Identifying the Targeted Audience.</li>
                                        <li>Unrestricted Space and Times and Rich Media.</li>
                                        <li>Keep Expanding & Integrate.</li>
                                        <li>Advancement, High Performance, and Economical.</li>
                                        <li>Technology-Supportive.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 features-image">
                            <div className="features-of-Website-img">
                                <img src={DigitalMarketing} alt="DigitalMarketing" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="get-a-free-mobile">
                <div className="get-a-free-mobile-all">
                    <div className="container">
                        <div className="row get-a-free-row">
                            <div className="col-9 get-a-free-col-9">
                                <div className="get-a-free-data">
                                    <h3>Elevate Your Online Presence with Strategic Digital Marketing!</h3>
                                    <h2>with our experts today!</h2>
                                </div>
                            </div>
                            <div className="col-3 get-a-free-col-3">
                                <div className="get-a-free-button">
                                    <a href="/contact">
                                        <button className="btn btn-warning">CONTACT US</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="our-expertise">
                <div className="container">
                    <div className="our-expertise-data-head">
                        <div className="our-expertise-data-top-head">
                            <h2>OUR EXPERTISE</h2>
                            <img className="underline" src={underline} alt="underline" />
                        </div>
                        <div className="our-core-data-bottom">
                            <div className="row our-expertise-data-row">
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="education-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_43_2} alt="Group_43_2" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Education</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="health-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_44_2} alt="Group_44_2" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Health Care</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="commerce-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_45_1} alt="Group_45_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>E-commerce</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="fitness-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_47_1} alt="Group_47_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Fitness</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="lifestyle-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_46_1} alt="Group_46_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Lifestyle</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="transportation-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_48_1} alt="Group_48_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Transportation</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="fitness-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_49_1} alt="Group_49_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Real Estate</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="event-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_50_1} alt="Group_50_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Event Management</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="lifestyle-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_51_1} alt="Group_51_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Travel</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="education-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_52_1} alt="Group_52_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Finance</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="health-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_53_1} alt="Group_53_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Social Network</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 our-expertise-data-col-2">
                                    <div className="commerce-expertise-card-data">
                                        <div className="row education-expertise-row">
                                            <div className="col-6 education-expertise-col-6-img">
                                                <div className="education-expertise-img">
                                                    <img src={Group_54_1} alt="Group_54_1" />
                                                </div>
                                            </div>
                                            <div className="col-6 education-expertise-col-6">
                                                <div className="education-expertise-data">
                                                    <p>Food & Drink</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Touch />

            <Footer />

        </div>
    )
}

export default Digital
