import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEnvelope,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
import logo from "../images/logonew1.png";
import { useEffect, useState } from "react";
import "../css/media.css";
import {
  faFacebookF,
  faGoogle,
  faInstagram,
  faLinkedinIn,
  faXTwitter,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import web_development from "../images/web-development.png";
import responsive_web_design_1 from "../images/responsive-web-design-1.png";
import hire_dedicate_dev from "../images/hire-dedicate-dev.png";
import mobile_app from "../images/mobile-app.png";
import responsive_mobile_design_1 from "../images/responsive-mobile-design-1.png";
import Python_1_1 from "../images/Python-1-1.png";
import digital_marketing_1 from "../images/digital-marketing-1.png";
import ui_ux_design from "../images/ui-ux-design.png";
import seo_1 from "../images/seo-1.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [ isScrolled, setIsScrolled ] = useState(false);

  useEffect(() => {
    function handleScroll() {
      setIsScrolled(window.scrollY > 50);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div>
        
      </div>
      <header>
        <div className="header-top">
          <div className="row">
            <div className="col-5">
              <div className="header-top-data">
                <div className="data-front-p">
                  <FontAwesomeIcon icon={faEnvelope} />
                  <a href="mailto:info@kalathiyainfotech.com">
                    Info@kalathiyainfotech.com
                  </a>
                </div>
              </div>
            </div>
            <div className="col-5 header-icons">
              <div className="header-top-bottom">
                <div className="data-icons">
                  <a
                    href="https://www.linkedin.com/company/kalathiyainfotech/"
                    target="_blank"
                  >
                    <FontAwesomeIcon className="i" icon={faLinkedinIn} />
                  </a>
                  <a
                    href="https://www.google.com/search?q=kalathiya+infotech&rlz=1C1GCEA_enIN1018IN1018&oq=Kalathiya+&aqs=chrome.0.69i59l2j69i57j69i59j69i60l4.17467j0j7&sourceid=chrome&ie=UTF-8"
                    target="_blank"
                  >
                    <FontAwesomeIcon className="i" icon={faGoogle} />
                  </a>
                  <a
                    href="https://www.instagram.com/kalathiya_infotech/"
                    target="_blank"
                  >
                    <FontAwesomeIcon className="i" icon={faInstagram} />
                  </a>
                  <a
                    href="https://www.facebook.com/kalathiyainfotech"
                    target="_blank"
                  >
                    <FontAwesomeIcon className="i" icon={faFacebookF} />
                  </a>
                  <a
                    href="https://www.youtube.com/@KalathiyaInfotech"
                    target="_blank"
                  >
                    <FontAwesomeIcon className="i" icon={faYoutube} />
                  </a>
                  <a href="https://x.com/KalathiyaInfo15" target="_blank">
                    <FontAwesomeIcon className="i" icon={faXTwitter} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-2 header-call">
              <div className="header-top-bottom">
                <div className="data-call">
                  <FontAwesomeIcon icon={faPhone} />
                  <a href="tel:9327569959">+91 9327569959</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={isScrolled ? "header scrolled" : "header"}>
          <div className="header-bottom">
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <a className="navbar-brand" href="#">
                  <img src={logo} alt="logo" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Link className="nav-link" aria-current="page" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/about">
                        About Us
                      </Link>
                    </li>
                    <li className="dropdo">
                      <a className="nav-link dropdown-toggle dropbt" href="#">
                        Services
                      </a>
                      <div className="dropdown sub_menu ">
                        <div className="dropdown-menu-all">
                          <div className="dropdown-manu">
                            <ul>
                              <li className="dropdown-li">
                                <Link
                                  to="/web_development"
                                  className="row dropdown-row"
                                >
                                  <div className="col-3 dropdown-col-3">
                                    <img
                                      src={web_development}
                                      alt="web_development"
                                    />
                                  </div>
                                  <div className="col-9 dropdown-col-9">
                                    <p>Web Development</p>
                                  </div>
                                </Link>
                              </li>
                              <li className="dropdown-li">
                                <Link
                                  to="/responsive_web"
                                  className="row dropdown-row"
                                >
                                  <div className="col-3 dropdown-col-3">
                                    <img
                                      src={responsive_web_design_1}
                                      alt="responsive_web_design_1"
                                    />
                                  </div>
                                  <div className="col-9 dropdown-col-9">
                                    <p>Responsive Web Design</p>
                                  </div>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown-manu">
                            <ul>
                              <li className="dropdown-li">
                                <Link
                                  to="/mobileapp"
                                  className="row dropdown-row"
                                >
                                  <div className="col-3 dropdown-col-3">
                                    <img src={mobile_app} alt="mobile_app" />
                                  </div>
                                  <div className="col-9 dropdown-col-9">
                                    <p>Mobile Application Development</p>
                                  </div>
                                </Link>
                              </li>
                              <li className="dropdown-li">
                                <Link
                                  to="/full_stack"
                                  className="row dropdown-row"
                                >
                                  <div className="col-3 dropdown-col-3">
                                    <img
                                      src={hire_dedicate_dev}
                                      alt="hire_dedicate_dev"
                                    />
                                  </div>
                                  <div className="col-9 dropdown-col-9">
                                    <p>Full Stack Development</p>
                                  </div>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown-manu">
                            <ul>
                              <li className="dropdown-li">
                                <Link to="/Seo" className="row dropdown-row">
                                  <div className="col-3 dropdown-col-3">
                                    <img src={seo_1} alt="seo_1" />
                                  </div>
                                  <div className="col-9 dropdown-col-9">
                                    <p>Search Engine Optimization</p>
                                  </div>
                                </Link>
                              </li>
                              <li className="dropdown-li">
                                <Link
                                  to="/digital"
                                  className="row dropdown-row"
                                >
                                  <div className="col-3 dropdown-col-3">
                                    <img
                                      src={digital_marketing_1}
                                      alt="digital_marketing_1"
                                    />
                                  </div>
                                  <div className="col-9 dropdown-col-9">
                                    <p>Digital Marketing</p>
                                  </div>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="dropdown-manu">
                            <ul>
                              <li className="dropdown-li">
                                <Link to="/uiux" className="row dropdown-row">
                                  <div className="col-3 dropdown-col-3">
                                    <img
                                      src={ui_ux_design}
                                      alt="ui_ux_design"
                                    />
                                  </div>
                                  <div className="col-9 dropdown-col-9">
                                    <p>UI/UX Design</p>
                                  </div>
                                </Link>
                              </li>
                              <li className="dropdown-li">
                                <Link
                                  to="/react_native"
                                  className="row dropdown-row"
                                >
                                  <div className="col-3 dropdown-col-3">
                                    <img src={Python_1_1} alt="Python_1_1" />
                                  </div>
                                  <div className="col-9 dropdown-col-9">
                                    <p>React Native</p>
                                  </div>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/contact">
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
          <div className="header-mobile-bottom">
            <nav className="navbar bg-body-tertiary">
              <div className="container-fluid">
                <a className="navbar-brand" href="#">
                  <img src={logo} alt="logo" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasNavbar"
                  aria-controls="offcanvasNavbar"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="offcanvas offcanvas-start"
                  tabIndex={-1}
                  id="offcanvasNavbar"
                  aria-labelledby="offcanvasNavbarLabel"
                >
                  <div className="offcanvas-header">
                    <a className="navbar-brand" href="#">
                      <img src={logo} alt="logo" />
                    </a>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    />
                  </div>
                  <div className="offcanvas-body">
                    <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                      <li className="nav-item">
                        <Link className="nav-link" aria-current="page" to="/">
                          Home
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/about">
                          About Us
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Services
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <div className="row">
                              <div className="col-2">
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                              <div className="col-10">
                                <Link
                                  to="/web_development"
                                  className="dropdown-item"
                                >
                                  Web Development
                                </Link>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="row">
                              <div className="col-2">
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                              <div className="col-10">
                                <Link
                                  to="/react_native"
                                  className="dropdown-item"
                                  href="#"
                                >
                                  React Native
                                </Link>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="row">
                              <div className="col-2">
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                              <div className="col-10">
                                <Link
                                  to="/uiux"
                                  className="dropdown-item"
                                  href="#"
                                >
                                  UI/UX Design
                                </Link>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="row">
                              <div className="col-2">
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                              <div className="col-10">
                                <Link
                                  to="/responsive_web"
                                  className="dropdown-item"
                                >
                                  Responsive Web Design
                                </Link>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="row">
                              <div className="col-2">
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                              <div className="col-10">
                                <Link
                                  to="/mobiledesign"
                                  className="dropdown-item"
                                >
                                  Mobile App Design
                                </Link>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="row">
                              <div className="col-2">
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                              <div className="col-10">
                                <Link to="/digital" className="dropdown-item">
                                  Digital Marketing
                                </Link>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="row">
                              <div className="col-2">
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                              <div className="col-10">
                                <Link to="/Seo" className="dropdown-item">
                                  Search Engine Optimization
                                </Link>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="row">
                              <div className="col-2">
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                              <div className="col-10">
                                <a className="dropdown-item" href="#">
                                  Hire Dedicated Development
                                </a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/contact">
                          Contact us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
