import React from "react";
import Vector_1 from "../images/seo.png";
import underline from "../images/underline.png";
import Group_11 from "../images/Group-11.jpg";
import Group_14 from "../images/Group-14.jpg";
import Group_15 from "../images/Group-15.jpg";
import Group_12_1 from "../images/Group-12-1.jpg";
import Group_13 from "../images/Group-13.jpg";
import Group_16 from "../images/Group-16.jpg";
import seo_2 from "../images/seo-2.png";
import Group_43_2 from "../images/Group-43-2.png";
import Group_44_2 from "../images/Group-44-2.png";
import Group_45_1 from "../images/Group-45-1.png";
import Group_47_1 from "../images/Group-47-1.png";
import Group_46_1 from "../images/Group-46-1.png";
import Group_48_1 from "../images/Group-48-1.png";
import Group_49_1 from "../images/Group-49-1.png";
import Group_50_1 from "../images/Group-50-1.png";
import Group_51_1 from "../images/Group-51-1.png";
import Group_52_1 from "../images/Group-52-1.png";
import Group_53_1 from "../images/Group-53-1.png";
import Group_54_1 from "../images/Group-54-1.png";
import OwlCarousel from "react-owl-carousel";
import quote1 from "../images/Double-quote1.png";
import quote2 from "../images/Double-quote2.png";
import quote3 from "../images/Double-quote3.png";
import quote4 from "../images/Double-quote4.png";
import vector1 from "../images/Vector1.png";
import vector2 from "../images/Vector-2.png";
import vector3 from "../images/Vector3.png";
import vector4 from "../images/Vector4.png";
import rico from "../images/rico.jpg";
import alex from "../images/alex.jpg";
import vaneet from "../images/vaneet.jpg";
import nick from "../images/nick.jpg";
import roman from "../images/roman.jpg";
import cedric from "../images/cedric.jpg";
import tom from "../images/tom.jpeg";
import Touch from "./Touch";
import Footer from "./Footer";

const Seo = () => {
  return (
    <div>
      <section className="We-Build-all">
        <div className="container">
          <div className="We-Build">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 we-build_text px-5">
                  <div className="we-data">
                    <div className="top-data">
                      <h2 className="software">
                        SEARCH ENGINE OPTIMIZATION</h2>
                    </div>
                    <div className="bottom-data">
                      <p>
                        Search Engine Optimization (SEO) is a digital marketing strategy aimed at improving a website’s visibility in search engine results pages (SERPs). Its aim of SEO is to Increase Organic (non-paid) Traffic to a website by optimizing various elements such as keywords, meta tags, content quality, & backlinks, and adhering to search engine algorithms’ guidelines.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 we-build_img">
                  <img src={Vector_1} alt="Vector_1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="web-application-development-services">
        <div className="container">
          <div className="web-application-services-data">
            <div className="web-application-services-data-top">
              <h2>SEARCH ENGINE OPTIMIZATION SERVICES</h2>
              <img className="underline" src={underline} alt="underline" />
              <p>
                Kalathiya Infotech LLP is a renowned website development service
                company catering to various industries with our bespoke website
                development services.
              </p>
            </div>
          </div>
          <div className="web-application-services-data-bottom">
            <div className="row web-application-card-row">
              <div className="col-4 web-application-card-col-4">
                <div className="custom-application-border-card Seo-card">
                  <div className="row web-application-row-img">
                    <div className="col-3 web-application-col-6-img">
                      <img src={Group_11} alt="Group_11" />
                    </div>
                    <div className="col-9 web-application-col-6-data-h3">
                      <h3>Keyword Research & Strategy</h3>
                    </div>
                  </div>
                  <p className="web-application-col-6-p">
                    Keyword research is indeed the foundation of effective SEO
                    (Search Engine Optimization). It involves the process of
                    identifying and analyzing the specific words and phrases that
                    people use when searching for information online.
                  </p>
                </div>
              </div>
              <div className="col-4 web-application-card-col-4">
                <div className="portal-application-border-card Seo-card">
                  <div className="row web-application-row-img">
                    <div className="col-3 web-application-col-6-img">
                      <img src={Group_14} alt="Group_14" />
                    </div>
                    <div className="col-9 web-application-col-6-data-h3">
                      <h3>Link Building</h3>
                    </div>
                  </div>
                  <p className="web-application-col-6-p">
                    Link building is a basic aspect of search engine optimization
                    (SEO). It involves the process of acquiring high-quality
                    backlinks from other reputable websites to improve your own
                    site’s authority, trustworthiness, and visibility in search
                    engine results pages (SERPs).
                  </p>
                </div>
              </div>
              <div className="col-4 web-application-card-col-4">
                <div className="commerce-application-border-card Seo-card">
                  <div className="row web-application-row-img">
                    <div className="col-3 web-application-col-6-img">
                      <img src={Group_15} alt="Group_15" />
                    </div>
                    <div className="col-9 web-application-col-6-data-h3">
                      <h3>Technical SEO</h3>
                    </div>
                  </div>
                  <p className="web-application-col-6-p">
                    This aspect focuses on the technical elements of a website,
                    ensuring that it’s structured and coded in a way that search
                    engines can easily crawl and index. This includes optimizing
                    site speed, and mobile-friendliness, and fixing crawl errors.
                  </p>
                </div>
              </div>
              <div className="col-4 web-application-card-col-4">
                <div className="integration-application-border-card Seo-card">
                  <div className="row web-application-row-img">
                    <div className="col-3 web-application-col-6-img">
                      <img src={Group_12_1} alt="Group_12_1" />
                    </div>
                    <div className="col-9 web-application-col-6-data-h3">
                      <h3>E-Commerce SEO</h3>
                    </div>
                  </div>
                  <p className="web-application-col-6-p">
                    E-commerce SEO, also known as online store SEO (Search engine
                    optimization) or retail SEO, is a specialized part of search
                    engine optimization that is tailored to e-commerce websites.
                    It focuses on optimizing various aspects of an online store to
                    improve its visibility in search engine results and drive
                    organic traffic.
                  </p>
                </div>
              </div>
              <div className="col-4 web-application-card-col-4">
                <div className="mobile-application-border-card Seo-card">
                  <div className="row web-application-row-img">
                    <div className="col-3 web-application-col-6-img">
                      <img src={Group_13} alt="Group_13" />
                    </div>
                    <div className="col-9 web-application-col-6-data-h3">
                      <h3>Content Writing</h3>
                    </div>
                  </div>
                  <p className="web-application-col-6-p">
                    Content writing is the skill & art of crafting well-crafted,
                    valuable, and captivating content with the aim of satisfying
                    the needs and interests of both search engines and human
                    audiences. It plays a crucial role in search engine
                    Optimization and digital marketing, online communication, and
                    information dissemination.
                  </p>
                </div>
              </div>
              <div className="col-4 web-application-card-col-4">
                <div className="flexible-application-border-card Seo-card">
                  <div className="row web-application-row-img">
                    <div className="col-3 web-application-col-6-img">
                      <img src={Group_16} alt="Group_16" />
                    </div>
                    <div className="col-9 web-application-col-6-data-h3">
                      <h3>Conversion Rate Optimization</h3>
                    </div>
                  </div>
                  <p className="web-application-col-6-p">
                    Conversion Rate Optimization (CRO) is indeed a critical aspect
                    of digital marketing that aims to enhance the percentage of
                    website visitors who complete specific desired actions or
                    conversions. These actions can vary depending on the goals of
                    a website or online platform, such as making a purchase,
                    signing up for a newsletter, or filling out a contact form.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <section className="features-of-Website-development ">
        <div className="container">
          <div className="row features-of-Website-row">
            <div className="col-6 features-of-Website-col-6">
              <div className="features-of-Website-data">
                <div className="features-of-h3">
                  <h3 className="features-top-h3">Features of</h3>
                  <h3 className="features-bottom-h3">
                    Search Engine Optimization
                  </h3>
                </div>
                <div className="features-of-p">
                  <p>
                    Search Engine Optimization (SEO) is a multifaceted approach
                    aimed at enhancing a website’s visibility in search engine
                    results pages (SERPs). SEO encompasses various features and
                    strategies that work together to achieve this goal.
                  </p>
                </div>
                <div className="features-of-ul">
                  <ul>
                    <li>Link analysis and acquisition.</li>
                    <li>Keyword research/rank analysis.</li>
                    <li>Adequate structure of a website</li>
                    <li>Technical SEO crawling.</li>
                    <li>Content marketing and analysis.</li>
                    <li>International search tracking.</li>
                    <li>Cross-device attribution.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-6 features-image">
              <div className="features-of-Website-img">
                <img src={seo_2} alt="seo_2" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="get-a-free-mobile">
        <div className="get-a-free-mobile-all">
          <div className="container">
            <div className="row get-a-free-row">
              <div className="col-9 get-a-free-col-9">
                <div className="get-a-free-data">
                  <h3>Unlock Your Online Potential with Expert SEO Strategies!</h3>
                  <h2>with our experts today!</h2>
                </div>
              </div>
              <div className="col-3 get-a-free-col-3">
                <div className="get-a-free-button">
                  <a href="/contact">
                    <button className="btn btn-warning">CONTACT US</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-expertise">
        <div className="container">
          <div className="our-expertise-data-head">
            <div className="our-expertise-data-top-head">
              <h2>OUR CORE SERVICES</h2>
              <img className="underline" src={underline} alt="underline" />
            </div>
            <div className="our-core-data-bottom">
              <div className="row our-expertise-data-row">
                <div className="col-2 our-expertise-data-col-2">
                  <div className="education-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_43_2} alt="Group_43_2" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Education</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="health-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_44_2} alt="Group_44_2" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Health Care</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="commerce-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_45_1} alt="Group_45_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>E-commerce</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="fitness-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_47_1} alt="Group_47_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Fitness</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="lifestyle-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_46_1} alt="Group_46_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Lifestyle</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="transportation-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_48_1} alt="Group_48_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Transportation</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="fitness-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_49_1} alt="Group_49_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Real Estate</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="event-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_50_1} alt="Group_50_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Event Management</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="lifestyle-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_51_1} alt="Group_51_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Travel</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="education-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_52_1} alt="Group_52_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Finance</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="health-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_53_1} alt="Group_53_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Social Network</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 our-expertise-data-col-2">
                  <div className="commerce-expertise-card-data">
                    <div className="row education-expertise-row">
                      <div className="col-6 education-expertise-col-6-img">
                        <div className="education-expertise-img">
                          <img src={Group_54_1} alt="Group_54_1" />
                        </div>
                      </div>
                      <div className="col-6 education-expertise-col-6">
                        <div className="education-expertise-data">
                          <p>Food & Drink</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Touch />
      <Footer />
    </div>
  );
};

export default Seo;
